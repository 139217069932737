import React, {useRef, useState} from 'react';
import styles from '../components/SideNav.module.scss';
import {Link} from 'react-router-dom';
import useOnClickOutside from "../hooks/useOnClickOutside";

const SideNav = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const menu = useRef();

    useOnClickOutside(menu, () => setMenuOpen(false))

    const toggleDropdown = () => {
        setMenuOpen(!menuOpen)
    };

    return (
        <div ref={menu} className={styles.sideNav}>
            <div className={styles.sideNavContainer}>
                <div onClick={toggleDropdown} className={styles.navIcon + ' ' + (menuOpen ? styles.open : '')}
                     id="navIcon">
                    <span/>
                    <span/>
                </div>
            </div>
            <div onClick={toggleDropdown}
                 className={styles.dropdownMenuContainer + ' ' + (menuOpen ? styles.show : styles.hide)}>
                <Link to='/work'>Work</Link>
                <Link to={'/contact'}>Contact</Link>
                <Link to={'/meet-me'}>Meet Me</Link>
            </div>
        </div>

    )
}

export default SideNav
