import React, {useEffect} from 'react';
import styles from '../screens/About.module.scss'
import resume from '../images/stewartallan_resume.pdf'
import Helmet from 'react-helmet'
import {Link} from "react-router-dom";
import headshot from "../images/headshot.jpg"
import ProjectsList from "../components/ProjectsList";

const About = ({projects}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Meet Me | stewartallan.com</title>
                <meta name='description' content=''/>
            </Helmet>
            <div className={styles.about}>
                <div className={styles.info}>
                    <div className={styles.col1}>
                        <h1 className={styles.title}>
                            Hello, I'm Stew, nice to meet you.
                        </h1>
                        <Link to={'/work'} className={styles.workButton}>See some work</Link>
                        <br/>
                        <br/>
                        <p>
                            I'm a creatively minded individual, with a passion for visual design,
                            a keen eye for margins, and a thirst for learning and expanding my
                            horizons, both literally and metaphorically.
                        </p>
                        <p>
                            Having followed a slightly un-conventional and circuitous route through fashion and textile
                            design,
                            I began delving into the world of visual design in 2015,
                            whilst working as a designer for a premium brand in the fashion industry.
                        </p>
                        <p>
                            I’ve spent the past 7 years developing those passions into a
                            broad skill-set, having worked in multi-disciplinary roles across Graphic, Visual, UX and UI
                            design,
                            but usually with a predominant focus on (and love for) the pixels. As an embedded part of
                            product
                            teams, I've also made it my business to understand how design is translated into real
                            world code, and so have a good working knowlege of HTML, CSS/SASS, and Javascript (React).
                        </p>
                        <p>
                            I've worked at both start-up level, and within larger company structures, usually in
                            hands-on,
                            challenging environments. I love solving problems, and have a habit (for better or worse) of
                            finding problems to solve that weren't part of the brief.
                        </p>
                        <p>
                            I bill myself as a visual designer, have a specific interest in all things
                            monochromatic, and spend my down-time trying to find balance (either on a surfboard,
                            in bird pose, or in the perfect milk to coffee ratio of a flat white).
                        </p>
                    </div>
                    <div className={styles.col2}>
                        <img src={headshot} alt={"pic of stew"}/>
                    </div>
                </div>
                <div className={styles.download}>
                    <a href={resume} download>
                            Download my CV/Resume here (.pdf)
                    </a>
                </div>
                <div style={{paddingTop: 40, background: 'white'}}>
                    <h1 className={styles.recent}>Recent Work</h1>
                    <ProjectsList count={2} projects={projects}/>
                </div>
            </div>
        </>
    )
}

export default About
