import React from 'react';
import styles from '../screens/Contact.module.scss'
import Helmet from 'react-helmet'


const Contact = () => {

    return (
        <>
            <Helmet>
                <title>Contact | stewartallan.com</title>
                <meta name='description' content=''/>
            </Helmet>
            <div className={styles.contact}>
                <div className={styles.title}>
                    <h3>
                        Email me
                    </h3>
                    <a href={'mailto:stewart@stewartallan.com'}>stewart@stewartallan.com</a>
                    <h3>
                        Let's connect
                    </h3>
                    <a href={'https://www.linkedin.com/in/stewart-allan/'} target='_blank' rel="noreferrer">
                        LinkedIn
                    </a>
                </div>
            </div>
        </>
    )
}

export default Contact

