import React, {useEffect} from 'react';
import styles from './ProjectContent.module.scss'
import {useParams} from "react-router-dom";

const ProjectContent = ({projects}) => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const params = useParams()

    const project = projects.length > 0 && projects.find(p => params.slug === p.slug)

    const body = (modules) => {
        return modules.map(m => {
            if (m.type === "text") {
                return <p key={m.content} dangerouslySetInnerHTML={{__html: m.content}}/>
            } else if (m.type === "image") {
                return <img key={m.content} className={"full-width-image"} src={m.content} alt={m.alt}/>
            } else if (m.type === "heading") {
                return <h3 key={m.content}>{m.content}</h3>
            } else if (m.type === "columns") {
                return <div key={m.c1} className={styles.columns}>
                    {m.c1 && <p dangerouslySetInnerHTML={{__html: m.c1}}/>}
                    {m.c2 && <p dangerouslySetInnerHTML={{__html: m.c2}}/>}
                </div>
            } else {
                return <div key={m.content}/>
            }
        })
    }

    return (
        <div className={styles.content}>
            <div className={styles.info}>
                <div className={styles.info1}>
                    {/*<h1>{project.name}</h1>*/}
                    <p dangerouslySetInnerHTML={{__html: project.information}}/>
                </div>
                <div className={styles.info2}>
                    <h3>role</h3>
                    <p>{project.tags ? project.tags.join(' / ') : ''}</p>
                    <h3>industry</h3>
                    <p>{project.industry}</p>
                    <h3>{project.website ? 'visit' : ''}</h3>
                    <p><a style={{textTransform: "lowercase", cursor: 'pointer'}} href={project.link} target={'_blank'} rel={'noreferrer'}>{project.website}</a></p>
                </div>
            </div>
            <div className={styles.modules}>{project.modules && body(project.modules)}</div>
            <div className={styles.modules} dangerouslySetInnerHTML={{__html: project.content}}>
            </div>
        </div>
    )
}

export default ProjectContent