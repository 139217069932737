import React from 'react';
import styles from '../screens/Projects.module.scss'
import {Link} from 'react-router-dom'
import defaultImage from '../images/acculex-bc.jpg'

const ProjectTile = ({project, idx}) => {

    if (!project) {
        return <div/>
    }

    const imageSrc = () => {
        if (!project.featuredImage || project.featuredImage.length < 1) {
            return defaultImage
        } else {
            return project.featuredImage
        }
    }

    const order = () => {
        if (idx % 2===0) {
            return "0"
        } else {
            return "1"
        }
    }

    return (
        <Link to={'/work/' + project.slug} className={styles.tile}>
            <img className={styles.tileImg} src={imageSrc()} alt={''} style={{order: order()}}/>
            <div className={styles.info}>
                <h2 className={styles.infoTitle}>
                    {project.name}
                </h2>
                <div className={styles.infoExcerpt} dangerouslySetInnerHTML={{__html: project.excerpt}}/>

                <div className={styles.infoTags}>
                    {project.tags.map(t => <div key={t} className={styles.tag}>{t}</div>)}
                </div>
                <div className={styles.linkButton}>Dig deeper</div>
            </div>
        </Link>
    )
}

export default ProjectTile