import './App.scss';
import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import About from "./screens/About";
import Contact from "./screens/Contact";
import ProjectContent from "./components/ProjectContent/ProjectContent";
import Projects from "./screens/Projects";
import SideNav from "./components/SideNav";
import axios from "axios";
import {useEffect, useState} from "react";

const App = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
            axios.get("/content.json"
            )
                .then(response => {
                    setProjects(response.data.projects);
                })
                .catch(error => console.log(error));
        }, []
    )

    return (
        <>
            <div>
                <div className={'page'}>
                    <Routes>
                        <Route exact path='/' element={<Navigate to="/meet-me"/>}/>
                        <Route exact path='/contact' element={<Contact/>}/>
                        <Route exact path='/meet-me' element={<About projects={projects}/>}/>
                        <Route exact path='/work' element={<Projects projects={projects}/>}/>
                        <Route path='/work/:slug' element={<ProjectContent projects={projects}/>}/>
                    </Routes>
                </div>
            </div>
            <SideNav/>
        </>
    );

}

export default App;
