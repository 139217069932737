import styles from "../screens/Projects.module.scss";
import ProjectTile from "../components/ProjectTile";
import React from "react";

const ProjectsList = ({projects, count}) => {

    if (count) {
        projects = projects.slice(0, count)
    }

    return <div className={styles.grid}>
        {projects.map((p, idx) => <ProjectTile key={idx} project={p} idx={idx}/>)}
    </div>
}

export default ProjectsList