import { useEffect } from "react";

export const useOnClickOutside = (ref, handleClick) => {
    useEffect(() => {
        const listener = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                handleClick(event);
            }
        };

        document.addEventListener("click", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("click", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handleClick]);
};

export default useOnClickOutside;