import React, {useEffect} from 'react';
import styles from '../screens/Projects.module.scss'
import Helmet from 'react-helmet'
import ProjectsList from "../components/ProjectsList";

const Projects = ({projects}) => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <div className={styles.projects}>
            <Helmet>
                <title>Work | stewartallan.com</title>
                <meta name='description' content=''/>
            </Helmet>
            <ProjectsList projects={projects}/>
        </div>
    )
}


export default Projects